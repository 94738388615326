

const helpers = {

  amountFormatter : function(param)
  {
    return param;
  },

  valueFormatter : function(params)
  {
    if ( parseFloat(params) == undefined )
      return '';

    if ( parseFloat(params) < 0 )
      return '(' + helpers.amountFormatter(Math.abs(params)) + ')'
    else
      return helpers.amountFormatter(params);
  },

  formatDate : function(dateInt)
  {
    if ( dateInt == undefined )
      return ''

    var dateString = dateInt.toString()
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);

    return `${year}-${month}-${day}`;
  },

  formatDateStrToYYYYMMDD: function(date_str)
  {
    var date = new Date(date_str);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
  },

  formatDateToYYYYMMDD: function(date)
  {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
  },


  computePercentageChanges: function(numbers)
  {
    if (numbers.length < 2) {
        throw new Error("The array must contain at least two numbers to calculate percentage changes.");
    }

    const percentageChanges = [null]; // The first element has no previous value

    var starting_price = numbers[0]

    for (let i = 1; i < numbers.length; i++) {
        //const previous = numbers[i - 1];
        const current = numbers[i];

        const change = ((current - starting_price) / Math.abs(starting_price)) * 100;
        percentageChanges.push(change);
    }

    return percentageChanges;
  }

}

export default helpers;
