import React from 'react'
import { useEffect, useState, useRef } from 'react';
import 'react-tabs/style/react-tabs.css';

import PortfolioDateGrid from './components/PortfolioDateGrid'
import MarketCapControl from './components/MarketCapControl'
import TopStocksPanel from './components/TopStocksPanel'
import IndustryPanel from './components/IndustryPanel'
import BasicStockChart from './components/BasicStockChart'
import StockPickPanel from './components/StockPickPanel'
import NewsPanel from './components/NewsPanel'
import RevenueAndIncomeChart from './components/RevenueAndIncomeChart'
import DescriptionPanel from './components/DescriptionPanel'
import ExternalLinksPanel from './components/ExternalLinksPanel'
import OwnershipPanel from './components/OwnershipPanel'
import SessionHelper from './components/SessionHelper'
import PositionPanel from './components/PositionPanel'

import { SESSION_URL } from './components/Constants'

import { GoogleLogin, GoogleOAuthenProvider, useGoogleLogin} from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { TailSpin } from  'react-loader-spinner'
import { Container, Row, Col } from 'react-bootstrap/';

import { jwtDecode } from "jwt-decode";

import 'bootstrap/dist/css/bootstrap.min.css'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


import './App.css';

function App() {


  const [rawData, setRawData] = useState();
  const [portfolioDates, setPortfolioDates] = useState();
  const [dateKey, setDateKey] = useState();
  const [chartData, setChartData] = useState();
  const [latestChartData, setLatestChartData] = useState()
  const [term, setTerm] = useState()
  const [ownershipData, setOwnershipData] = useState()
  const [showLoading, setShowLoading] = useState(false);

  const [portfolioDatesData, setPortfolioDatesData] = useState(undefined);
  const [portfolioPositionData, setPortfolioPositionData] = useState();
  const [portfolioIndustryData, setPortfolioIndustryData] = useState();
  const [portfolioDescriptions, setPortfolioDescriptions] = useState();
  const [industryRankDescription, setIndustryRankDescription] = useState();
  const [industryTickerList, setIndustryTickerList] = useState();
  const [userCredentials, setUserCredentials] = useState();
  const [positions, setPositions] = useState();
  const [logonResponse, setLogonResponse] = useState();

   const [panel, setPanel] = useState();
   const [newsChartPanel, setNewsChartPanel] = useState();
   const [news, setNews] = useState();
   const [newsUrl, setNewsURL] = useState();
   const [stockPickPanel, setStockPickPanel] = useState();



  const [industryData, setIndustryData] = useState([0]);
  const [businessDate, setBusinessDate] = useState([0]);

  const industryDataRef = React.useRef();
  const topStocksPanelRef = React.useRef();
  const stockPickPanelRef = React.useRef();

  const business_date = useRef(0);
  const selected_term = useRef(180);
  const row_data = useRef(null);
  const currentIndustryRankArray = React.useRef([]);

	//const url = "http://127.0.0.1:8000";
  // const url = "https://www.itopstocks.com/";
  //const url = "https://alpharesearch.online/";

  const Report_date_change = ( data ) =>
  {
    setDateKey(data)
  }

  const Term_data_change = ( term_change ) =>
  {
      setTerm(term_change);
  }

  function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
  }

  useEffect(() => {

    if ( term != undefined && dateKey != undefined )
    {
      dateKey['company_size'] = term;

      const requestOptionsResults = { method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dateKey) };

      setPortfolioPositionData(undefined)
      setPortfolioIndustryData(undefined)

      fetch(SESSION_URL + '/portfolio_position_data/',requestOptionsResults)
      .then(res => res.json())
      .then((result) =>
        {

          setPortfolioPositionData(result.data)
          setPortfolioIndustryData(result.industry_data)

          //setDateKey(result)
          setPortfolioDescriptions("Positions : " + result.date_str + " - " + result.price_date_str )
          setIndustryRankDescription("Industry Ranks as of " + result.date_str )

          //topStocksPanelRef.current.populate_top_stock_data(result.data);
        }
      );
    }

  }, [term, dateKey]);


  const Stock_pick_change = ( data ) =>
  {
      var id_key_request = {};
      id_key_request['start_date'] = data['open_date']
      id_key_request['end_date'] = data['price_date']
      id_key_request['instrument_details'] = data['instrument_details']
      id_key_request['tickers'] = []
      id_key_request['tickers'].push('SPY')
      id_key_request['tickers'].push(data['symbol'])

      const requestOptionsResults = { method: 'POST', headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(id_key_request) };
      fetch(SESSION_URL + '/chart/', requestOptionsResults) .then(res => res.json())
      .then(result =>
          {
            setChartData(result);

            var symbol = result.instrument_details.symbol
            var news = {}
            news['news_list'] = result.news[symbol]
            news['symbol'] = symbol
            setNews(news)

            var ownership = {}
            ownership = result.data[symbol].ownership
            setOwnershipData(ownership)
          }
    );
  }

  const Industry_pick_change = ( data ) =>
  {
    const requestOptionsResults = { method: 'POST', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data) };
    fetch(SESSION_URL + '/industry_view/', requestOptionsResults) .then(res => res.json())
    .then(result =>
        {
          setIndustryTickerList(result.data)
        }
  );
  }

  const Done_loading = () =>
  {
      setShowLoading(false);
  }


  const HandleSelect = (key) => {
    //alert(key);
      //industryDataRef.current.populate_industry_data(row_data.current, business_date.current, selected_term.current);

      if (key === 0 )
      {
        ResetCurrentIndustryState();
      };
  }

  const ResetCurrentIndustryState = () =>
  {
  }

  useEffect(() => {

    const requestOptionsData = {};
    setShowLoading(true)

    console.log(SESSION_URL)

    fetch(SESSION_URL + '/portfolio_dates_data/',requestOptionsData)
    .then(res => res.json())
    .then((result) =>
      {
        setShowLoading(false)
        setRawData(result)
      }
    );

  }, []);

  useEffect(() => {

    if ( rawData !== undefined  && term !== undefined && porfolio_dates == undefined)
    {
      var porfolio_dates = []

      rawData.data.forEach((item, i) => {

        if ( item['company_size'] == 'Medium' )
          porfolio_dates.push(item)

      });

      setPortfolioDates(porfolio_dates)
    }

  }, [rawData]);

  useEffect(() => {

    if ( rawData !== undefined  && term !== undefined )
    {
      var porfolio_dates = {}

      rawData.data.forEach((item, i) => {

        if ( item['company_size'] == term )
          porfolio_dates[item['date_key']] = item

      });

      setPortfolioDatesData(porfolio_dates)
    }

  }, [term, rawData]);

  const responseMessage = (response) => {

    const USER_CREDENTIAL = jwtDecode(response.credential);
    setUserCredentials(USER_CREDENTIAL);

  };


  useEffect(() => {

   if (userCredentials !== undefined )
   {
     //console.log(userCredentials);

     SessionHelper.submit_login(userCredentials, (logon_response) => { setLogonResponse(logon_response['data']) });
     setPositions(undefined);
     //setExposure(undefined);
   }

 }, [userCredentials]);

 useEffect(() => {

  if ( logonResponse != undefined)
  {
      var position_request = {}
      position_request = {}
      position_request['email'] = logonResponse['email']
      position_request['jti'] = logonResponse['jti']

      SessionHelper.get_positions(position_request, (positions_in) =>
      {
          if ( positions_in !== undefined && positions_in.positions !== undefined )
          {
            setPositions(positions_in);
          }

        });
    };

}, [logonResponse]);


  const errorMessage = (error) => { console.log(error); };

 const logged_in_div =
 <div style={{textAlign:'left', paddingTop:'5px'}}>
   <Row>
     <Col>
       {userCredentials == undefined ? null : userCredentials.name}
     </Col>
   </Row>
   <Row>
     <Col style={{fontSize:'10px'}}>
       {userCredentials == undefined ? null : userCredentials.email}
     </Col>
   </Row>
 </div>

  return (
    <div>
    <nav>
      <Row style={{width:'100%', display: 'flex', justifyContent: 'space-around'}}>
        <Col>
        <h3 className="nav--logo_text">
            <div style={{paddingLeft:'10px'}}>
              Alpha Research Online
            </div>
            <div style={{paddingLeft:'10px', paddingBottom:'5px', fontSize: '1.2rem',
              fontWeight:'400', lineHeight: '1.75rem', color:'#8A91A0'}}>
              Beat the market!
            </div>
          </h3>
        </Col>
          <GoogleOAuthProvider clientId="269679075110-abs309177qbif3tanvu464gk14d9d2rk.apps.googleusercontent.com">
        <Col>
        <Row style={{fontSize:'14px',  textAlign: 'right', textWrap: 'nowrap', float: 'right'}}>
        <Col>
          <div>
          {
            userCredentials == undefined ? <div style={{paddingTop:'15px'}}>Sign-in with</div> : logged_in_div
          }
          </div>
        </Col>
        <Col style={{paddingTop:'10px'}}>
              <GoogleLogin onSuccess={responseMessage} onError={errorMessage} theme='filled_black' size='medium' type='icon'/>
        </Col>
        </Row>
        </Col>
        </GoogleOAuthProvider>
      </Row>
  </nav>
    <Container fluid>
        <div style={ ( showLoading == true ) ? {pointerEvents: "none", opacity: "0.4"} : {}}>
          <Row>
              <Col xs={6} md={3}>
                <Row>
                  <MarketCapControl termChangeCallback={Term_data_change}/>
                </Row>
                <Row>
                  <PortfolioDateGrid reportDateChangeCallback={Report_date_change}
                    termChangeCallback={Term_data_change} portfolioDates={portfolioDates}
                    portfolioDatesData={portfolioDatesData} dateKey={dateKey} ref={industryDataRef}/>
                </Row>
                <Row>
                <div style={{display: 'flex', justifyContent: 'center', fontSize:'14px', fontWeight:'500', paddingTop:'0px'}}>
                  <a className="App-link"  href="mailto:AlphaResearchOnline@gmail.com">Support</a>
                </div>
                </Row>
              </Col>
              <Col xs={6} md={6}>
              <Row>
              <Col>
                    <Tabs id="controlled-tab-example" activeKey={panel}
                      onSelect={(active_panel) => setPanel(active_panel)}
                      style={{paddingTop:'15px', color:'#61dafb', fontSize:'15px'}}>
                    <Tab style={{width:'100%',  backgroundColor: 'transparent'}} eventKey="portfolios" title="Portfolio">
                      <Row style={{paddingTop:'5px', color:'#61dafb', fontSize:'13px'}}>
                    <Col style={{textAlign:'Right'}}> { portfolioDescriptions }
                  </Col>
                  </Row>
                  <Row>
                    <TopStocksPanel portfolioPositionData={portfolioPositionData}
                      stockPickChangeCallback={Stock_pick_change} ref={topStocksPanelRef}
                      />
                  </Row>
                  </Tab>
                  <Tab style={{width:'100%'}} eventKey="industries" title="Industries">
                  <Row style={{padding:'5px', color:'#61dafb', fontSize:'13px'}}>
                    <Col style={{textAlign:'Left'}}>
                      { industryRankDescription }
                    </Col>
                  </Row>
                  <Row>
                    <IndustryPanel
                      portfolioIndustryData={portfolioIndustryData}
                      industryPickChangeCallback={Industry_pick_change}
                      industryTickerList={industryTickerList}
                      stockPickChangeCallback={Stock_pick_change}
                      dateKey={dateKey}
                      activePanel={panel}
                      ref={topStocksPanelRef} />
                    </Row>
                  </Tab>
                  <Tab style={{width:'100%'}} eventKey="watchList" title="Watch List" disabled={userCredentials == undefined ? true : false }>
                    <Row style={{paddingTop:'5px', color:'#61dafb'}}>
                    <Col style={{textAlign:'Left'}}>
                      Watch List
                    </Col>
                    </Row>
                  <Row>
                      <div className="ag-theme-balham-dark">
                        <PositionPanel positions={positions} stockPickChangeCallback={Stock_pick_change}/>
                      </div>
                  </Row>
                  </Tab>
                  </Tabs>
                  <Row>
                  <Col>
                  {
                  <Tabs id="chart_news_panel" activeKey={newsChartPanel}
                  onSelect={(active_panel) => setNewsChartPanel(active_panel)}
                  style={{paddingTop:'15px',width:'98%',color:'#61dafb'}}>
                  <Tab style={{width:'100%'}} eventKey="chart" title="Position Chart">
                    <BasicStockChart stockData={chartData} chartType='chart'/>
                  </Tab>
                  <Tab style={{width:'100%'}} eventKey="latestchart" title="Latest Chart">
                    <BasicStockChart stockData={chartData} chartType='chart_1Y'/>
                  </Tab>
                  <Tab style={{width:'100%'}} eventKey="news" title="Latest News">
                      <NewsPanel news={news} onNewsSelect={(news_url) => setNewsURL(news_url)}/>
                  </Tab>
                  <Tab style={{width:'100%'}} eventKey="revenue_and_income_chart" title="Revenue/Income">
                      <RevenueAndIncomeChart stockData={chartData} onNewsSelect={(news_url) => setNewsURL(news_url)}/>
                  </Tab>
                  <Tab style={{width:'100%'}} eventKey="institutional_holdings" title="Inst. Holdings">
                      <OwnershipPanel ownershipData={ownershipData}/>
                  </Tab>
                </Tabs>
                  }
                  </Col>
                  </Row>
                  </Col>
              </Row>
              </Col>
              <Col xs={6} md={3}>
                <Tabs id="stock_pick_panel" activeKey={stockPickPanel} onSelect={(active_panel) => setStockPickPanel(active_panel)} style={{paddingTop:'15px', color:'#61dafb'}}>
                <Tab style={{width:'100%', height:"40vh"}} eventKey="LatestMD" title="Market Data">
                  <Row>
                    <StockPickPanel
                      stockData={chartData}
                      externalLinks={rawData}
                      url={newsUrl}
                      userCredentials={userCredentials}
                      positions={positions}
                      reloadPositions=
                        {(positions_in)=>
                          {
                            if ( positions_in != undefined)
                            {
                                setPositions(positions_in);
                                setPanel("watchList")
                            }
                          }
                        }/>
                  </Row>
                </Tab>
                <Tab style={{width:'100%', height:"40vh"}} eventKey="descrption" title="Description">
                  <DescriptionPanel stockData={chartData}/>
                </Tab>
                <Tab style={{width:'100%', height:"40vh"}} eventKey="externalLinks" title="External Links">
                  <ExternalLinksPanel stockData={chartData} externalLinks={rawData}/>
                </Tab>
                </Tabs>
              </Col></Row>
        </div>
    </Container>
    </div>
  );
}

export default App;
