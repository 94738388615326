import { useEffect, useState } from 'react';
import React from 'react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import AdSense from 'react-adsense';

import helpers from './helpers'

const { forwardRef, useRef, useImperativeHandle } = React;

const TopStocksPanel = React.forwardRef ((props, ref) => {

  const gridBestStocksRef = React.useRef();
  const gridWorstStocksRef = React.useRef();

  const [industryName, setIndustryName] = useState([""]);

  const columnDefs = [
    /*{headerName : 'Positions', style:{'align':'center'},
    children:[*/
    { field: 'id_key', hide: true },
    { headerName: 'Industry Name', field: 'industry_name', width: 200  },
    { field: 'symbol', width: 80  },
    { headerName: 'Chg(%)',
      field: 'change', sortable: true,flex: 1, width: 40, cellStyle: {'text-align': 'right'},
      cellRenderer : params => {
      if ( params.data.change >= 0.0 )
      {
        return ( <div><span>
             <span style={{color: "rgb(152,195,121)"}}> { helpers.valueFormatter (params.data.change ) } % </span>
             </span></div> );
      } else {
         return ( <div><span>
              <span style={{color: "rgb(224,108,118)"}}> { helpers.valueFormatter (params.data.change ) } % </span>
              </span></div> );
      }
    } },
    { headerName: 'Open Price',field: 'open_price',flex: 1, width: 80, cellStyle: {'text-align': 'right', 'fontWeight':'400'}},
    { headerName: 'Close/Mkt Price', field: 'market_price',flex: 1, width: 80, cellStyle: {'text-align': 'right', 'fontWeight':'400'}},
    { headerName: 'Open Date', field: 'open_date',flex: 1, width: 80, cellStyle: {'text-align': 'right' , 'fontWeight':'400'},
    cellRenderer : params => {
      return ( <div><span>
           <span> { params.data.open_date.toString().replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3') } </span>
           </span></div> );
    }},
    { headerName: 'Close/Mkt Date', field: 'price_date',flex: 1, width: 80, cellStyle: {'text-align': 'right' , 'fontWeight':'400'},
    cellRenderer : params => {
      return ( <div><span>
           <span> {
                params.data.price_date.toString().replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
             } </span>
           </span></div> );
    }},
    { field: 'name',   cellStyle: {'text-align': 'left'}, flex: 1 }
  //]},
  ];

  const gridOptions = {

      rowSelection: 'single',

      onRowClicked: event => {
        console.log('row', event);

        props.stockPickChangeCallback( event.data );
      },

      onCellClicked: event => {
        // setBusinessDate(event.data.Date);
        console.log('cell', event);
      },

      onRowDataChanged: event => {
        var defaultSortModel = [
          { colId: 'change', sort: 'desc', sortIndex: 0 }
        ];
        gridBestStocksRef.current.columnApi.applyColumnState({ state: defaultSortModel });


        gridBestStocksRef.current.api.forEachNode(function(node) {

          if ( node.rowIndex == 0 )
          {
            node.setSelected(true);
            props.stockPickChangeCallback( node.data );
            return;
          }
        })
      },

      onColumnResized: event => console.log('A column was resized'),

      // CALLBACKS
      getRowHeight: (params) => 25
  }

  const gridOptions1 = {

      rowSelection: 'single',

      onRowClicked: event => {
        console.log('row', event);
        props.stockPickChangeCallback( event.data );
      },

      onCellClicked: event => {
        // setBusinessDate(event.data.Date);
        console.log('cell', event);
      },

      onColumnResized: event => console.log('A column was resized'),

      // CALLBACKS
      getRowHeight: (params) => 25
  }

   useEffect(() => {
         if ( gridBestStocksRef.current.api != undefined && props.portfolioPositionData != undefined )
         {

           var row_data_array = [];
           props.portfolioPositionData.forEach((item, i) => {
             var stock_entry = {}
             stock_entry['instrument_details'] = item['instrument_details']
             stock_entry['name'] = item['instrument_details']['name']
             stock_entry['symbol'] = item['instrument_details']['symbol']
             stock_entry['industry_name'] = item['instrument_details']['industry_name']

             var change_percent = ((item['position_details']['market_price'] -
                item['position_details']['open_price'])/item['position_details']['open_price'])*100.0
             stock_entry['change'] = Math.round(change_percent * 100) / 100
             stock_entry['open_date'] = item['position_details']['open_date']
             stock_entry['open_price'] = item['position_details']['open_price']
             stock_entry['market_price'] = item['position_details']['market_price']
             stock_entry['price_date'] = item['position_details']['price_date']

             row_data_array.push(stock_entry)
           });


           gridBestStocksRef.current.api.setRowData(row_data_array);

       }


   }, [props.portfolioPositionData]);


   return (
            <div className="ag-theme-balham-dark" style={{width:'100%', height:"40vh"}}>
              <AgGridReact columnDefs={columnDefs} gridOptions={gridOptions} ref={gridBestStocksRef}>
              </AgGridReact>
            </div>
     )
  });

export default TopStocksPanel;
