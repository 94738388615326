import { useEffect, useState } from 'react';
import React from 'react';

import helpers from './helpers'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

const { forwardRef, useRef, useImperativeHandle } = React;

const PositionPanel = React.forwardRef ((props, ref) => {

  const gridRef = React.useRef();

  const [positions, setPositions] = useState();

  const columnDefs = [
    /*{headerName : 'Portfolio', style:{'align':'center'},
    children: [*/
    { field: 'industry_name', headerName: 'Industry Name', width:'200px'},
    { field: 'symbol', headerName: 'Symbol', width:'75px'},
    { field: 'price', headerName: 'Price', width:'75px',flex: 1, cellStyle: {'text-align': 'right'},},
    { field: 'quantity', headerName: 'Quantity', width:'75px', flex: 1, cellStyle: {'text-align': 'right'},},
    { field: 'pnl',  headerName: 'P&L', width:'75px', flex: 1, cellStyle: {'text-align': 'right'},

    cellRenderer : params => {

    if ( params.data.pnl>0)
    {
      return ( <div><span>
           <span style={{color: "rgb(152,195,121)"}}> {params.data.pnl} </span>
           </span></div> );
    } else if (params.data.pnl<0) {
       return ( <div><span>
            <span style={{color: "rgb(224,108,118)"}}> {params.data.pnl} </span>
            </span></div> );
    } else {
      return ( <div><span>
           <span> {params.data.pnl} </span>
           </span></div> );
    }
    },
    },
    {
      field: 'price_date', headerName: 'Open Date', width:'100px',
        valueFormatter: params => {
            return helpers.formatDate(params.data.price_date)
        },
    },
    {
      field: 'end_date', headerName: 'MTM Date', width:'100px',
        valueFormatter: params => {
            return helpers.formatDate(params.data.end_date)
        },
    },
    { field: 'name', headerName: 'Name'},

  ];


  useEffect(() => {

        if ( gridRef.current.api != undefined && props.positions != undefined )
        {

          var positions_out = []

          props.positions.positions.forEach((position_in, i) => {
            console.log(position);
            var position = {}
            position['symbol'] = position_in['instrument_details']['symbol']
            position['industry_name'] = position_in['instrument_details']['industry_name']
            position['instrument_details'] = position_in['instrument_details']
            position['name'] = position_in['instrument_details']['name']
            position['open_date'] = position_in.position_details['open_date']
            position['price_date'] = helpers.formatDateToYYYYMMDD(new Date())

            positions_out.push(position)

            if ( position_in.position_details.buy_amt-position_in.position_details.sell_amt != 0 )
		        {
			           position['price'] = (position_in.position_details.buy_amt*position_in.position_details.buy_avg_price -
                   position_in.position_details.sell_amt*position_in.position_details.sell_avg_price)/
                   (position_in.position_details.buy_amt-position_in.position_details.sell_amt);
                 position['quantity'] = (position_in.position_details.buy_amt-position_in.position_details.sell_amt)
                 position['pnl'] = (position_in.market_data.day.vw - position['price']) * position['quantity']
		        } else {
			           position['price'] = 0;
                 position['quantity'] = 0;
		        }

          });

          setPositions(positions_out)
        }

    }, [props.positions]);


  const gridOptions = {

      rowSelection: 'single',

      onRowClicked: event => {
        console.log('row', event);
        //props.onNewsSelect(event.data.article_url)
        props.stockPickChangeCallback( event.data )
      },

      onRowSelected: event => {
        console.log("RowSelected!!!!!" + event.data.industry_id );
      },

      onCellClicked: event => {
        console.log("Indstury Pick :" + event.data.industry_id);
      },

      onColumnResized: event => console.log('A column was resized'),

      onGridReady: event =>
      {
      },
  }


   return (

     <div>
       <div className="ag-theme-balham-dark" style={{height:"35vh"}}>
           <AgGridReact rowData={positions}
              columnDefs={columnDefs}
              gridOptions={gridOptions} ref={gridRef}>
           </AgGridReact>
       </div>
       </div>
     )
  });

export default PositionPanel;
