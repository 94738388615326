import { SESSION_URL } from './Constants'
import SessionHelper from './SessionHelper'
import helpers from './helpers'

const TradeCaptureHelper = {

  submit_trade: function(userCredentials, positions, instrument, side, price, quantity, callback)
  {
      var symbol = instrument['symbol'];
      var position = undefined;


      if ( positions['positions'] !== undefined )
      {
        for (const position_in of positions['positions'])
        {
          if ( position_in['symbol'] == symbol )
          {
            position =  position_in['position_details']
            break;
          }
        }
      }

      if (position == undefined)
      {
        position = {}
        position['buy_amt'] = 0;
        position['buy_avg_price'] = 0;
        position['sell_amt'] = 0;
        position['sell_avg_price'] = 0;
        position['open_date'] = Number(helpers.formatDateToYYYYMMDD(new Date()))
      }

      if ( side === 'buy' )
      {
        var new_amt = position['buy_amt'] + quantity;
        var new_avg_price = ((position['buy_amt'] * position['buy_avg_price']) + (price * quantity))/new_amt;
        position['buy_amt'] = new_amt;
        position['buy_avg_price'] = new_avg_price;
      } else {
        var new_amt = position['sell_amt'] + quantity;
        var new_avg_price = ((position['sell_amt'] * position['sell_avg_price']) + (price * quantity))/new_amt;
        position['sell_amt'] = new_amt;
        position['sell_avg_price'] = new_avg_price;
      }

      //positions[symbol] = position;

      //console.log(positions);

      var updated_position = {}
      updated_position['login_data'] = {}
      updated_position['login_data']['email'] = userCredentials['email']
      updated_position['login_data']['jti'] = userCredentials['jti']
      updated_position['position_data'] = {}
      updated_position['position_data']['instrument_details'] = instrument
      position['symbol'] = symbol
      updated_position['position_data']['position_details'] = position

      SessionHelper.insert_update_position(updated_position, (position_update_response) => { callback(position_update_response); });

      console.log(updated_position);
  },

};

export default TradeCaptureHelper;
