import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart }            from 'react-chartjs-2';
import { useEffect, useState } from 'react';

import helpers from './helpers';


const { forwardRef, useRef, useImperativeHandle } = React;

const BasicStockChart = React.forwardRef ((props, ref) => {

  const chartRef = useRef();

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const [options, setOptions] = useState({
    labels: [],
    datasets: [],
  });


  useEffect(() => {

          console.log(props.stockData);

          if ( props.stockData == undefined)
            return;

          var benchmark_price_data = props.stockData.data['SPY'][props.chartType]
          var benchmark_labels = [];
          var benchmark_price = [];

          for (const [key, value] of Object.entries(benchmark_price_data))
          {
            benchmark_price.push(parseFloat(value.close_price));
            benchmark_labels.push(value.price_date_out);
          }

          benchmark_price = helpers.computePercentageChanges(benchmark_price)

          var selected_symbol = props.stockData['instrument_details']['symbol']
          var selected_stock_price_data = props.stockData.data[selected_symbol][props.chartType]
          var selected_symbol_labels = [];
          var selected_symbol_price = [];

          for (const [key, value] of Object.entries(selected_stock_price_data))
          {
            selected_symbol_price.push(parseFloat(value.close_price));
            selected_symbol_labels.push(value.price_date_out);
          }

          selected_symbol_price = helpers.computePercentageChanges(selected_symbol_price)

          const char_data = {
            type: 'line',
            labels: benchmark_labels,
            datasets: [
              {
                label: selected_symbol,
                data: selected_symbol_price,
                fill:true,
                backgroundColor: "rgb(101, 210, 241, 0.1)",
                borderColor: '#65d2f1',
                yAxisID: 'y',
                hidden: false,
                borderWidth: 1.5,
                lineTension: 0.4

              },
              {
                label: 'SPY',
                data: benchmark_price,
                fill:true,
                backgroundColor: "rgb(255, 165, 0, 0.1)",
                borderColor: '#FFA500',
                yAxisID: 'y',
                hidden: false,
                borderWidth: 1.5,
                lineTension: 0.4
              }
            ]
          };


          setData(char_data);

          var start_date = props.stockData.start_date.toString().replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
          var end_date = props.stockData.end_date.toString().replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');

          const options = {
            responsive: true,
            interaction: {
              mode: 'index',
              intersect: false,
            },
            elements: {
                   point:{
                       radius: 1.125
                   }
               },
            stacked: false,
            plugins: {
              title: {
                display: true,
                text: 'Prices/Yields'
              }
            },
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left',
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',

                // grid line settings
                grid: {
                  drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
              },
            }
        //  },
        };

            setOptions(options)


  }, [ props.stockData, props.chartType]);


  return <Line options={options} data={data} ref={chartRef}/>;
});

export default BasicStockChart;
