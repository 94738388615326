import { useEffect, useState } from 'react';
import React from 'react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import AdSense from 'react-adsense';

import helpers from './helpers'

import { parse } from 'date-fns';

const { forwardRef, useRef, useImperativeHandle } = React;

const OwnershipPanel = React.forwardRef ((props, ref) => {

  const gridBestStocksRef = React.useRef();
  const gridWorstStocksRef = React.useRef();

  const [industryName, setIndustryName] = useState([""]);
  const [columnHeaders, setColumnHeaders] = useState([""]);


  const gridOptions = {

      rowSelection: 'single',

      onRowClicked: event => {
        console.log('row', event);

        props.stockPickChangeCallback( event.data );
      },

      onCellClicked: event => {
        // setBusinessDate(event.data.Date);
        console.log('cell', event);
      },

      onRowDataChanged: event => {
        var defaultSortModel = [
          { colId: 'change', sort: 'desc', sortIndex: 0 }
        ];
        gridBestStocksRef.current.columnApi.applyColumnState({ state: defaultSortModel });


        gridBestStocksRef.current.api.forEachNode(function(node) {

          console.log(node.data)
          /*if ( node.rowIndex == 0 )
          {
            node.setSelected(true);
            props.stockPickChangeCallback( node.data );
            return;
          }*/
        })
      },

      onColumnResized: event => console.log('A column was resized'),

      // CALLBACKS
      getRowHeight: (params) => 25
  }

  const gridOptions1 = {

      rowSelection: 'single',

      onRowClicked: event => {
        console.log('row', event);
        props.stockPickChangeCallback( event.data );
      },

      onCellClicked: event => {
        // setBusinessDate(event.data.Date);
        console.log('cell', event);
      },

      onColumnResized: event => console.log('A column was resized'),

      // CALLBACKS
      getRowHeight: (params) => 25
  }

   useEffect(() => {
         if ( gridBestStocksRef.current.api != undefined && props.ownershipData != undefined )
         {
           var report_quarters = []
           var filing_manager_report = {}

           props.ownershipData.forEach((item, i) => {

                var filing_manager = item['coverpage']['FILINGMANAGER_NAME'];
                var report_calendar = item['coverpage']['REPORTCALENDARORQUARTER'];
                var share_count = item['infotable']['SSHPRNAMT'];

                if (!report_quarters.includes(report_calendar))
                {
                  report_quarters.push(report_calendar)
                }

                if ( !(filing_manager in filing_manager_report) )
                {
                  filing_manager_report[filing_manager] = {}
                }

                if ( share_count == undefined )
                  share_count = 0

                filing_manager_report[filing_manager][report_calendar]=share_count
           });

           report_quarters.sort(function(a,b) {
              var date_a = parse(a, 'dd-MMM-yyyy', new Date());
              var date_b = parse(b, 'dd-MMM-yyyy', new Date());

              return new Date(date_b) - new Date(date_a);
            });

            const columnDefs = [
              { headerName: 'Institution', field: 'institution', width: 200, sortable: true },
            ];

            report_quarters.forEach((item, i) => {
              console.log("Date : " + item)

              var date_header = item

              var column_dict = {headerName: date_header, field: date_header, sortable: true, flex: 1, width: 40,  type: 'rightAligned',
                cellRenderer : params => {

                    var sorted_headers = []
                    gridBestStocksRef.current.props.columnDefs.forEach((item, i) => {
                      //console.log("Date : " + item)
                      console.log(item)
                      sorted_headers.push(item['field'])
                    });

                    var header_name = params.colDef.field;
                    var data_in = params.data.reports[header_name]

                    if ( data_in != undefined )
                    {
                      var data_in = parseInt(params.data['reports'][header_name])
                      var start_compare = false;
                      var prev_filing = data_in;

                      for (const filing_date of sorted_headers) {
                        console.log(filing_date);

                          if (filing_date == header_name)
                          {
                            start_compare = true;
                          } else if ( start_compare == true && params.data['reports'][filing_date] != undefined )
                          {
                            prev_filing = parseInt(params.data['reports'][filing_date]);
                            break;
                          }
                        };

                        var data_in_str = data_in.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                        if ( prev_filing > data_in )
                        {
                          return (
                              <div><span>
                              <span style={{color: "rgb(224,108,118)"}}> { data_in_str } </span>
                              </span></div>
                            );
                        } else {
                          return (
                              <div><span>
                              <span style={{color: "rgb(152,195,121)"}}> { data_in_str } </span>
                              </span></div>
                            );
                        }
                    }
                  },
                }

              columnDefs.push(column_dict)

            });

            setColumnHeaders(columnDefs)


            var row_data_array = []

            for (const [institution, reports] of Object.entries(filing_manager_report)) {

                var len = Object.keys(reports).length
                if ( len == 1 )
                  continue;

                var row_data = {}
                row_data['institution'] = institution
                row_data['reports'] = reports

                /*for ( const [report_date, share_count] of Object.entries(reports)) {
                    row_data[report_date] = parseInt(share_count)
                }*/

                row_data_array.push(row_data)
            }

            gridBestStocksRef.current.api.setRowData(row_data_array);

       }


   }, [props.ownershipData]);


   return (
            <div className="ag-theme-balham-dark" style={{width:'100%', height:"35vh"}}>
              <AgGridReact columnDefs={columnHeaders} gridOptions={gridOptions} ref={gridBestStocksRef}>
              </AgGridReact>
            </div>
     )
  });

export default OwnershipPanel;
