/*  This file is part of ql_rest, a free-software/open-source library
    for utilization of QuantLib over REST */
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import LabeledNumericInput from './LabeledNumericInput'
import TradeCaptureHelper from './TradeCaptureHelper'


//import TradeCaptureHelper from '../helpers/TradeCaptureHelper'
//import SegmentedControl from './SegmentedControl'

import React from 'react'
import { useEffect, useState, useRef } from 'react';
import { compareAsc, format, parseISO } from 'date-fns'

const Ticket = React.forwardRef ((props, ref) => {

  const [price, setPrice] = useState();
  const [instrument, setInstrument] = useState({'symbol':'','name':':'});
  const [instrumentType, setInstrumentType] = useState('stock');
  const [optionType, setOptionType] = useState('call');
  const [quantity, setQuantity] = useState();
  const [quantityLabel, setQuantityLabel] = useState('Quantity');
  const [quantityIncrement, setQuantityIncrement] = useState();
  const [ticketDisabled, setTicketDisabled] = useState();

  useEffect(() =>
  {
    if ( props.stockPrices == undefined )
      return;

      //var symbol = props.ticker.Symbol
      //var instrument = {}
      //instrument['name'] = props.ticker.Name

      var market_data_in = props.stockPrices.market_data[0].market_data
      var stats = props.stockPrices.market_data[0].stats
      var real_time_price =  market_data_in.day.vw

      var instrument_in = props.stockPrices['instrument_details']

      if ( instrument_in['symbol'] != instrument['symbol'])
          setTicketDisabled(false);

      setPrice(real_time_price)
      setQuantity(100)
      setQuantityIncrement(100)
      setQuantityLabel('Quantity')
      setInstrument(instrument_in)

  }, [props, instrument]);

  const tradeBookingDone = (response) => {
      props.tradeBookCallback(response)
      //setTicketDisabled(false)
  };

  return (

      <Container style={ticketDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
        <Row style={{paddingTop:'10px'}}>
          <Col xs={4}>
              <LabeledNumericInput label="Price" value={price} elementName="price"
                  onChange={(elementName, new_value)=>{
                    setPrice(parseFloat(new_value));
                  }} onChanged= {(elementName, new_value)=>{ /*setGreeksDisabled(true);*/ }} postfix='' step={0.50}/>
            </Col>
            <Col xs={4}>
                  <LabeledNumericInput label={quantityLabel} value={quantity}
                  elementName="quantity" onChange={(elementName, new_value)=>{
                    setQuantity(parseInt(new_value));
                  }}
                  onChanged= {(elementName, new_value)=>{ /*setGreeksDisabled(true);*/ }} postfix='' step={quantityIncrement}/>
            </Col>
              <Col xs={2} style={{justifyContent:'right', alignItems:'right', display: 'flex', paddingTop:'5px'}}>
                <Button size="sm" onClick={()=> {
                    setTicketDisabled(true)
                    TradeCaptureHelper.submit_trade(props.userCredentials,
                      props.positions, instrument, 'buy', price, quantity, tradeBookingDone)
                  }}>Buy</Button>
              </Col>
              <Col xs={2} style={{justifyContent:'right', alignItems:'right', display: 'flex', paddingTop:'5px'}}>
                <Button size="sm" onClick={()=> {
                  setTicketDisabled(true)
                  TradeCaptureHelper.submit_trade(props.userCredentials,
                    props.positions, instrument, 'sell', price, quantity, tradeBookingDone);
                }}>Sell</Button>
              </Col>
        </Row>
      </Container>
    )
 });

export default Ticket;
