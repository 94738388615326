import { SESSION_URL } from './Constants'


const SessionHelper = {

  submit_login: function(request, callback)
  {
      var result_out = {}

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
      };

      fetch(SESSION_URL + '/submit_login/', requestOptions)
        .then(res => res.json())
        .then(sessionData => { callback( sessionData )
        } );

      return result_out;
  },

  insert_update_position: function(request, callback)
  {
      var result_out = {}

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
      };

      fetch(SESSION_URL + '/insert_update_position/', requestOptions)
        .then(res => res.json())
        .then(sessionData => { callback( sessionData )
        } );

      return result_out;
  },

  get_positions: function(request, callback)
  {
      var result_out = {}

      request["portal"] = "BOND_PORTAL";

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request)
      };

      fetch(SESSION_URL + '/get_positions/', requestOptions)
        .then(res => res.json())
        .then(positionData => { callback( positionData )
        } );

      return result_out;
  },


};

export default SessionHelper;
